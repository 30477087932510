.preloader{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preloader > .icon{
   width: 100px;
   height: 100px;
   animation: spinner-plncf9 2s infinite linear;
}
/* .spinner {
   width: 56px;
   height: 56px;
   display: grid;
   animation: spinner-plncf9 1s infinite;
} */
@media screen and (max-width: 768px) {
   .preloader > .icon{
      width: 50px;
      height: 50px;
   }
}

@keyframes spinner-plncf9 {
   0%{
      transform: rotate(-180deg);
   }
   50%{
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(-180deg);
   }
}


/* .spinner::before,
.spinner::after {
   content: "";
   grid-area: 1/1;
   border: 9px solid;
   border-radius: 50%;
   border-color: #fdbb11 #fdba118e #0000 #0000;
   mix-blend-mode: darken;
   animation: spinner-plncf9 1s infinite linear;
}

.spinner::after {
   border-color: #0000 #0000 #ffffff #ffffff;
   animation-direction: reverse;
}

 */