/* This stylesheet generated by Transfonter (https://transfonter.org) on October 5, 2017 10:21 PM */

* {
    box-sizing: border-box;
}

// $main-dark: #1E1E1E;
// $text-dark: var(--text-dark);
// $text-grey: var(--text-grey);

:root {
    --main-dark: #1E1E1E;
    --text-grey: #E9E6E6;
    --text-dark: #443D3D;
    --text-grayer: #cbcbcb;
}

.light {
    --main-dark: #EBE9E5;
    --text-grey: #3E3838;
    --text-dark: #cbcbcb;
    --text-grayer: #3E3838;
}

@function toVw($size) {
    @return ($size * 100 / 1728 * 1vw)
}

;

@function toVwMobile($sizeMobile) {
    @return ($sizeMobile * 100 / 430 * 1vw)
}

;

.dr {
    background: var(--main-dark);
}


a {
    text-decoration: none;
}

.container {
    width: toVw(1628);
    margin: 0 auto;

    @media (max-width: 768px) {
        width: calc(100% - toVwMobile(40));
    }
}

header {
    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        background: var(--main-dark);
        z-index: 5;
        width: 100%;
        padding: toVwMobile(20) 0;
    }

    .container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
    }

    .logo {
        cursor: pointer;
        position: fixed;
        left: toVw(50);
        top: toVw(50);
        max-width: toVw(32);
        z-index: 10;
        transition: .3s;

        @media (hover: hover) {
            &:hover {
                transform: scale(1.1);
            }
        }

        img {
            width: 100%;
            display: block;

            @media (max-width: 768px) {
                width: 100vw;
                max-width: 100%;
            }
        }

        @media (max-width: 768px) {
            width: 100vw;
            max-width: toVwMobile(32);
            position: static;
            display: block;
        }
    }

    .mid {
        padding-top: toVw(5);
        cursor: pointer;
        position: fixed;
        left: 50%;
        top: toVw(55);
        transform: translateX(-50%);
        z-index: 10;

        @media (max-width: 768px) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        svg {

            circle,
            path {
                transition: .3s;
            }

            &:hover {
                circle {
                    fill: #EBE9E5;
                    stroke: #3E3838;
                }

                path {
                    fill: #3E3838;
                }
            }
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: fixed;
        right: toVw(50);
        top: toVw(50);
        z-index: 10;

        @media (max-width: 768px) {
            right: inherit;
            top: inherit;
            left: 0;
            bottom: 0;
            padding: toVwMobile(15) toVwMobile(10);
            white-space: nowrap;
            display: block;
            overflow-x: auto;
            max-width: 100%;
            background: var(--main-dark);
        }

        a {
            display: block;
            color: var(--text-grey);
            font-family: 'Futura';
            font-size: toVw(18);
            font-style: normal;
            font-weight: 500;
            line-height: 79.5%;
            letter-spacing: toVw(.88);
            text-transform: uppercase;
            text-decoration: none;
            margin-bottom: toVw(40);
            transition: .3s;
            position: relative;
            width: fit-content;

            @media (max-width: 768px) {
                display: inline-block;
                margin-right: toVwMobile(30);
                font-size: toVwMobile(16);
                margin-bottom: 0;
            }

            &.active {
                color: var(--text-grey, var(--text-grey));


                &::before {
                    opacity: 1;
                    left: toVw(-34);

                    @media (max-width: 768px) {
                        left: 50%;
                        top: toVwMobile(-10);
                    }
                }
            }

            &:hover {
                @media (min-width: 768px) {
                    color: var(--text-grey, var(--text-grey));

                    &::before {
                        opacity: 1;
                        left: toVw(-34);

                        @media (max-width: 768px) {
                            left: 50%;
                            top: toVwMobile(-10);
                        }
                    }
                }
            }

            &::before {
                content: '';
                display: block;
                width: toVw(4);
                height: toVw(4);
                border-radius: 50%;
                background: #FDBB11;
                position: absolute;
                left: toVw(-4);
                top: 50%;
                transform: translateY(-50%);
                transition: .3s;
                opacity: 0;

                @media (max-width: 768px) {
                    left: 50%;
                    top: toVwMobile(-10);
                    transform: translate(-50%, -50%);
                    width: toVwMobile(4);
                    height: toVwMobile(4);
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.lang {
    position: fixed;
    left: toVw(50);
    bottom: toVw(50);
    display: flex;
    align-items: center;
    z-index: 10;

    @media (max-width: 768px) {
        position: fixed;
        left: inherit;
        bottom: inherit;
        right: toVwMobile(20);
        top: toVwMobile(35);
    }

    p {
        color: var(--text-dark);
        font-family: 'Futura';
        font-size: toVw(16);
        font-style: normal;
        font-weight: 500;
        line-height: 79.5%;
        letter-spacing: toVw(0.88);
        text-transform: uppercase;
        transition: .3s;
        cursor: pointer;

        @media (max-width: 768px) {
            font-size: toVwMobile(16);
        }

        &.active,
        &:hover {
            color: var(--text-grey, var(--text-grey));

        }

        &:last-child {
            margin-right: 0;
        }
    }

    span {
        color: var(--text-dark);
        font-family: 'Futura';
        font-size: toVw(16);
        font-style: normal;
        font-weight: 500;
        line-height: 79.5%;
        letter-spacing: toVw(0.88);
        text-transform: uppercase;
        transition: .3s;

        @media (max-width: 768px) {
            font-size: toVwMobile(16);
        }
    }
}

.social-links {
    position: fixed;
    right: toVw(50);
    bottom: toVw(50);
    z-index: 10;

    @media (max-width: 768px) {
        display: none;
        right: toVwMobile(20);
        bottom: inherit;
        top: toVwMobile(50);
    }

    a {
        color: var(--text-grey);
        text-align: right;
        font-family: 'Futura';
        font-size: toVw(16);
        font-style: normal;
        font-weight: 500;
        line-height: 79.5%;
        letter-spacing: toVw(0.88);
        text-transform: uppercase;
        display: block;
        margin-bottom: toVw(26);
        transition: .3s;

        @media (max-width: 768px) {
            font-size: toVwMobile(16);
            color: var(--text-grey);
        }

        &:hover {
            color: #FDBB11;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.section1 {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: toVw(80) 0;
    overflow: hidden;

    @media (max-width: 768px) {
        padding: toVwMobile(60) 0;
    }

    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
            justify-content: center;
        }

        .name {
            color: var(--text-grey, var(--text-grey));
            font-family: 'Futura';
            font-size: toVw(24);
            font-style: normal;
            font-weight: 700;
            line-height: 79.5%;
            letter-spacing: toVw(5.52);
            text-transform: uppercase;
            margin-bottom: toVw(32);

            @media (max-width: 768px) {
                font-size: toVwMobile(24);
                margin-bottom: toVwMobile(20);
            }
        }

        .main-title {
            h1 {
                display: block;
                text-align: center;
                color: var(--text-grey);
                text-align: center;
                font-family: 'Futura';
                font-size: toVw(128);
                font-style: normal;
                font-weight: 800;
                line-height: 83.5%;
                letter-spacing: toVw(-0.64);
                text-transform: uppercase;
                position: relative;
                width: fit-content;
                margin: 0 auto;
                max-width: toVw(1000);
                width: fit-content;

                @media (max-width: 768px) {
                    font-size: toVwMobile(45);
                    line-height: 100%;
                    max-width: toVwMobile(350);
                }

                &.yellow {
                    color: #FDBB11;

                    div {
                        position: relative;
                        width: fit-content;
                        display: inline-block;

                        &::before {
                            content: '';
                            display: block;
                            width: toVw(34);
                            height: toVw(34);
                            position: absolute;
                            top: toVw(-5);
                            right: 0;
                            transform: translateX(100%);
                            background-image: url(../img/zirochka.svg);
                            background-size: contain;
                            background-repeat: no-repeat;

                            @media (max-width: 768px) {
                                right: toVwMobile(-0);
                                top: 0%;
                                // transform: translateY(-50%);
                                width: toVwMobile(34);
                                height: toVwMobile(34);
                            }
                        }
                    }
                }
            }
        }

        .explanation {
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FDBB11;
            font-family: 'Futura';
            font-size: toVw(16);
            font-style: normal;
            font-weight: 500;
            line-height: 79.5%;
            letter-spacing: toVw(0.88);
            position: absolute;
            left: 50%;
            bottom: toVw(50);
            transform: translateX(-50%);

            @media (max-width: 768px) {
                margin: 0;
                position: absolute;
                left: 50%;
                bottom: toVwMobile(60);
                transform: translateX(-50%);
            }

            @media (max-width: 768px) {
                max-width: toVwMobile(270);
                font-size: toVwMobile(16);
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            img {
                display: block;
                margin-right: 20px;

                @media (max-width: 768px) {
                    margin-right: 0;
                    margin-bottom: toVwMobile(23);
                }
            }
        }
    }
}

@keyframes letter-animation {
    0% {
        color: var(--text-dark);
    }

    100% {
        color: var(--text-grey);
    }
}

@keyframes arrow1-animation {
    0% {
        right: 0;
        top: toVw(-60);
    }

    50% {
        right: toVwMobile(12);
        top: toVw(-43);
    }

    100% {
        right: 0;
        top: toVw(-60);
    }
}

@keyframes arrow2-animation {
    0% {
        top: toVw(53);
        right: 0;
    }

    50% {
        top: toVw(18);
        right: toVw(42);
    }

    100% {
        top: toVw(53);
        right: 0;
    }
}

@keyframes arrow3-animation {
    0% {
        top: toVw(85);
        left: toVw(72);
    }

    50% {
        top: toVw(47);
        right: toVw(119);
    }

    100% {
        top: toVw(85);
        left: toVw(72);
    }
}

.section2 {
    height: calc(var(--vh, 1vh) * 200);
    position: relative;

    .hrline {
        position: absolute;
        top: calc(50% - 5px);
        left: 0;
        width: 100%;
        z-index: -100;
        height: 10px;
    }

    @media (max-width: 768px) {
        padding-top: toVwMobile(140);
        padding-bottom: toVwMobile(150);
        height: calc(var(--vh, 1vh) * 300);
    }

    .container {
        max-width: toVw(1052);
        height: calc(var(--vh, 1vh) * 100);
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 768px) {
            max-width: 100%;
            width: 100%;
            padding: 0 toVwMobile(20);
            overflow: hidden;
            height: calc(var(--vh, 1vh) * 120);

        }

        .slogan {
            color: var(--text-dark);
            font-family: 'Futura';
            font-size: toVw(90);
            font-style: normal;
            font-weight: 500;
            line-height: 109.5%;
            letter-spacing: toVw(-3.03);
            margin: 0 auto;
            margin-bottom: toVw(40);

            span {
                transition: .8s;

                &.active {
                    color: var(--text-grey);
                }
            }

            @media (max-width: 768px) {
                font-size: toVwMobile(48);
                margin-bottom: toVwMobile(50);
            }
        }

        .btn-download-wrapper {
            color: #FDBB11;
            font-family: 'Futura';
            font-size: toVw(16);
            font-style: normal;
            font-weight: 500;
            line-height: 79.5%;
            letter-spacing: toVw(1.6);
            text-transform: uppercase;
            width: fit-content;
            margin-left: auto;
            position: relative;

            @media (max-width: 768px) {
                font-size: toVwMobile(16);
            }

            .arrow1 {
                position: absolute;
                right: 0;
                top: toVw(-60);
                transform: translate(50%, -100%);
                animation-name: arrow1-animation;
                animation-duration: 2s;
                animation-iteration-count: infinite;

                @media (max-width: 768px) {
                    top: toVwMobile(-60);
                }
            }

            .arrow2 {
                position: absolute;
                top: toVw(53);
                right: 0;
                transform: translate(50%, 0);
                animation-name: arrow2-animation;
                animation-duration: 2s;
                animation-iteration-count: infinite;

                @media (max-width: 768px) {
                    top: toVwMobile(53);
                }
            }

            .arrow3 {
                position: absolute;
                top: toVw(85);
                left: toVw(72);
                animation-name: arrow3-animation;
                animation-duration: 2s;
                animation-iteration-count: infinite;

                @media (max-width: 768px) {
                    top: toVwMobile(85);
                    left: toVwMobile(72);
                }
            }

            a {
                color: inherit;
                position: relative;
                display: flex;
                align-items: center;

                &:hover {
                    &::before {
                        transform: translateX(toVw(10));
                    }
                }

                &::before {
                    content: '';
                    display: block;
                    width: toVw(33);
                    height: toVw(8);
                    background-image: url(../img/arrow-right-orange-icon.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-right: toVw(20);
                    transition: .3s;

                    @media (max-width: 768px) {
                        width: toVwMobile(33);
                        height: toVwMobile(8);
                    }
                }
            }
        }
    }
}


.section3 {
    display: flex;
    padding-top: toVw(128);
    position: relative;
    height: toVw(450);

    @media (max-width: 768px) {
        height: inherit;
        padding-top: toVwMobile(200);
        padding-bottom: toVwMobile(100);
        overflow: hidden;
    }

    * {
        flex-shrink: 0;
    }

    .we,
    .ar,
    .e,
    .weua,
    .arua {
        img {
            width: 100%;
            height: 100%;

            @media (max-width: 768px) {
                height: inherit;
                object-fit: contain;
                object-position: bottom;
            }
        }
    }

    .we {
        width: toVw(542);
        height: toVw(328);
        transform: rotate(16.877deg);
        position: absolute;
        left: toVw(-115);
        top: toVw(0);

        @media (max-width: 768px) {
            width: toVwMobile(176);
            left: toVwMobile(-37);
            top: toVwMobile(30);
            height: toVwMobile(130);
        }
    }

    .weua {
        width: toVw(868);
        height: toVw(328);
        transform: rotate(15deg);
        position: absolute;
        left: toVw(-101);
        top: toVw(0);

        @media (max-width: 768px) {
            width: toVwMobile(300);
            left: toVwMobile(-37);
            top: toVwMobile(30);
            height: toVwMobile(130);
        }
    }

    .arua {
        width: toVw(289);
        height: toVw(328);
        transform: rotate(0);
        position: absolute;
        left: toVw(768);
        top: toVw(436);

        @media (max-width: 768px) {
            width: toVwMobile(100);
            left: toVwMobile(260);
            top: toVwMobile(100);
            height: toVwMobile(130);
        }
    }

    .ar {
        width: toVw(500);
        height: toVw(328);
        position: absolute;
        left: toVw(409);
        top: toVw(306);

        @media (max-width: 768px) {
            width: toVwMobile(162);
            top: toVwMobile(142);
            left: toVwMobile(170);
            height: toVwMobile(130);
        }
    }

    .e {
        width: toVw(165);
        height: toVw(328);
        transform: rotate(-6.788deg);
        position: absolute;
        left: toVw(887);
        top: toVw(182);

        @media (max-width: 768px) {
            width: toVwMobile(53);
            top: toVwMobile(113);
            left: toVwMobile(358);
            height: toVwMobile(130);
        }
    }

    .wrapper {
        transform: rotate(-6.05deg);
        position: absolute;
        left: toVw(1135);
        top: toVw(280);

        @media (max-width: 768px) {
            position: static;
            transform: none;
            padding: 0 toVwMobile(20);
            width: 100%;
            height: toVwMobile(300);
        }

        .item {
            margin-bottom: toVw(10);
            cursor: default;

            &:hover {
                @media (min-width: 768px) {
                    p {
                        color: var(--text-grey, var(--text-grey));

                        &::before {
                            opacity: 1;
                        }

                        img {
                            opacity: 1;
                        }
                    }

                    span {
                        opacity: 1;
                    }
                }
            }

            &.active {
                p {
                    color: var(--text-grey, var(--text-grey));

                    &::before {
                        opacity: 1;
                    }

                    img {
                        opacity: 1;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            p {
                color: var(--text-dark, var(--text-dark));
                font-family: 'Futura';
                font-size: toVw(64);
                font-style: normal;
                font-weight: 500;
                margin-bottom: toVw(4);
                line-height: 112%;
                transition: .3s;
                position: relative;
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 768px) {
                    font-size: toVwMobile(60);
                    margin-bottom: toVwMobile(4);
                }

                img {
                    display: block;
                    width: toVw(42);
                    margin-right: toVw(5);
                    transition: .3s;
                    opacity: 0;

                    @media (max-width: 768px) {
                        width: toVwMobile(42);
                        margin-right: toVwMobile(5);
                    }
                }

                &::before {
                    content: '';
                    display: none;
                    width: toVw(29);
                    height: toVw(29);
                    background-image: url(../img/sun-icon-yellow.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    right: toVw(-10);
                    top: 0;
                    transform: translateX(100%);
                    opacity: 0;
                    transition: .3s;

                    @media (max-width: 768px) {
                        width: toVwMobile(29);
                        height: toVwMobile(29);
                        right: toVwMobile(-10);
                    }
                }
            }

            span {
                color: var(--text-grayer);
                font-family: 'Futura';
                font-size: toVw(16);
                font-style: normal;
                font-weight: 500;
                line-height: 79.5%;
                transition: .3s;
                opacity: 0;

                @media (max-width: 768px) {
                    font-size: toVwMobile(16);
                    opacity: 1;
                    transition: none;
                    display: none;
                }
            }
        }
    }
}

.section4 {
    cursor: url(../img/arrow-right-orange-icon.svg), pointer;
    margin-bottom: toVw(150);
    .item {
        height: toVw(125);
        position: relative;
        display: block;
        text-decoration: none;
        cursor: url(../img/arrow-right-orange-icon.svg), pointer;

        @media (max-width: 768px) {
            height: inherit;
        }

        &:nth-child(1) {
            .container {
                background: #262525;
            }
        }

        &:nth-child(2) {
            .container {
                background: #2C2B2B;
            }
        }

        &:nth-child(3) {
            .container {
                background: #373737;
            }
        }

        &:nth-child(4) {
            .container {
                background: #4B4B4B;
            }
        }

        &:nth-child(5) {
            .container {
                background: #5C5C5C;
            }
        }

        &:nth-child(6) {
            .container {
                background: #737373;
            }
        }

        .container {
            height: toVw(125);
            transition: .3s;
            width: 100%;
            padding: 0 toVw(150);
            overflow: hidden;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            display: flex;
            justify-content: space-between;
            padding-top: toVw(40);

            @media (max-width: 768px) {
                position: static;
                width: 100%;
                transform: none;
                height: inherit;
                padding: toVwMobile(20);
                flex-direction: column;
            }

            p {
                color: rgba($color: #B4B4B4, $alpha: .2);
                font-family: 'Futura';
                font-size: toVw(101);
                font-style: normal;
                font-weight: 500;
                line-height: 109.5%;
                letter-spacing: toVw(-3.03);
                transition: .3s;

                @media (max-width: 768px) {
                    font-size: toVwMobile(48);

                    &.active {
                        color: #B4B4B4;
                    }
                }
            }

            span {
                color: #B4B4B4;
                font-family: 'Futura';
                font-size: toVw(20);
                font-style: normal;
                font-weight: 500;
                line-height: 126.5%;
                display: block;
                max-width: toVw(427);
                opacity: 0;
                transition: .3s;

                @media (max-width: 768px) {
                    font-size: toVwMobile(20);
                    max-width: 100%;
                    overflow: hidden;
                    display: none;
                    opacity: 1;
                    transition: none;
                }
            }

            .learn-more {
                display: none;

                @media (max-width: 768px) {
                    display: none;
                    overflow: hidden;
                    color: #FDBB11;
                    font-family: 'Futura';
                    font-size: toVwMobile(16);
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: toVwMobile(1.6);
                    text-transform: uppercase;
                    text-align: right;
                    margin-top: toVwMobile(40);

                    &::before {
                        content: '';
                        display: block;
                        width: toVwMobile(33);
                        height: toVwMobile(8);
                        background-image: url(../img/arrow-right-orange-icon.svg);
                        display: inline-block;
                        background-size: cover;
                        background-repeat: no-repeat;
                        margin-right: toVwMobile(20);
                    }
                }
            }

            &:hover {
                @media (min-width: 768px) {
                    height: toVw(175);

                    p {
                        color: rgba($color: #B4B4B4, $alpha: 1);
                    }

                    span {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.section5 {
    position: relative;
    margin-bottom: toVw(250);
    overflow: hidden;

    @media (max-width: 768px) {
        margin-bottom: toVwMobile(100);
        display: flex;
        align-items: center;
        justify-content: center;

        &.mtm {
            margin-top: toVwMobile(80);
        }
    }

    .hr {
        display: none;

        @media (max-width: 768px) {
            display: block;
            width: 100%;
            height: 1px;
            position: fixed;
            left: 0;
            top: 50%;
            z-index: 5;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0 0 toVw(150) 0;
        min-height: toVw(970);

        @media (max-width: 768px) {
            padding: 0;
            width: 100%;
            min-height: inherit;
        }

        .item {
            @media (max-width: 768px) {
                height: toVwMobile(200);
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 100%;
            }

            &:hover,
            &.active {
                cursor: url(../img/arrow-right-orange-icon.svg), pointer;


                .img-wrapper {
                    transition: .3s;
                    opacity: 1;
                }
            }

            h3 {
                color: #B4B4B4;
                text-align: center;
                font-family: 'Futura';
                font-size: toVw(64);
                font-style: normal;
                font-weight: 500;
                line-height: 112%;
                transition: .3s;
                text-align: center;
                position: relative;
                z-index: 2;
                width: fit-content;
                margin: 0 auto;

                @media (max-width: 768px) {
                    font-size: toVwMobile(48);
                    line-height: 144%;
                }

                span {
                    color: inherit;
                    font-family: 'Futura';
                    font-size: toVw(20);
                    font-style: normal;
                    font-weight: 700;
                    line-height: 117%;
                    text-transform: uppercase;
                    position: absolute;
                    right: -20px;
                    top: 50%;
                    transform: translateX(100%);

                    @media (max-width: 768px) {
                        font-size: toVwMobile(20);
                        position: static;
                        transform: none;
                        display: block;
                    }
                }
            }

            .img-wrapper {
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: .5s .3s;
                z-index: 1;
                pointer-events: none;

                @media (max-width: 768px) {
                    opacity: 1;
                    z-index: 0;
                }

                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    fill: rgba(32, 31, 28, 0.51);
                    backdrop-filter: blur(15.5px);
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

            }
        }
    }
}

.section6 {
    padding: 0 0 toVw(100) 0;
    height: calc(var(--vh, 1vh) * 200);
    position: relative;

    @media (max-width: 768px) {
        padding: toVwMobile(30) 0 toVwMobile(90) 0;
    }

    .container {
        height: calc(var(--vh, 1vh) * 100);
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
            color: var(--text-dark);
            font-family: 'Futura';
            font-size: toVw(101);
            font-style: normal;
            font-weight: 500;
            line-height: 109.5%;
            letter-spacing: toVw(-3.03);
            max-width: toVw(1235);
            margin: 0 auto;

            span {
                transition: .8s;

                &.active {
                    color: var(--text-grey);
                }
            }

            @media (max-width: 768px) {
                font-size: toVwMobile(44);
                max-width: 100%;
            }
        }
    }
}

.section7 {
    padding-top: toVw(60);
    
    //overflow: hidden;

    @media (max-width: 768px) {
        padding-top: 0;
        margin-bottom: toVwMobile(120);
    }

    .container {
        display: flex;
        justify-content: space-between;
        max-width: toVw(1230);

        @media (max-width: 768px) {
            max-width: calc(100% - toVwMobile(40));
            position: relative;
        }

        .wrapper {
            max-width: toVw(540);

            @media (max-width: 768px) {
                max-width: 100%;
            }

            &:nth-child(2) p {
                left: inherit;
                right: 20%;
            }

            p {
                color: var(--text-grayer);
                font-family: 'Futura';
                font-size: toVw(20);
                font-style: normal;
                font-weight: 700;
                line-height: 128.5%;
                text-transform: uppercase;
                margin-bottom: toVw(70);

                @media (max-width: 768px) {
                    font-size: toVwMobile(20);
                    color: #3E3838;
                    text-align: center;
                    position: absolute;
                    left: 20%;
                    top: toVwMobile(-40);


                    &.active {
                        color: var(--text-grayer);
                    }
                }

            }

            .list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                @media (max-width: 768px) {
                    display: none;

                    &.active {
                        display: flex;
                    }
                }

                .item {
                    width: toVw(139);
                    margin-right: toVw(60);
                    margin-bottom: toVw(60);

                    &:nth-child(3n) {
                        margin-right: 0;
                    }

                    @media (max-width: 768px) {
                        width: calc(33% - toVwMobile(25));
                        margin-right: toVwMobile(25);
                        margin-bottom: toVwMobile(45);
                    }

                    img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.section8 {
    padding-top: toVw(240);
    padding-bottom: toVw(250);
    overflow: hidden;

    @media (max-width: 768px) {
        margin-bottom: toVwMobile(160);
    }

    .container {
        max-width: toVw(1230);

        @media (max-width: 768px) {
            max-width: 100%;
            width: 100%;
            padding-left: toVwMobile(20);
        }

        .wrapper {
            display: flex;
            justify-content: space-between;

            @media (max-width: 768px) {
                white-space: nowrap;
                overflow-x: auto;
                overflow-y: hidden;
                display: flex;
                max-width: 100%;
                padding-bottom: toVwMobile(20);
            }

            .item {
                max-width: toVw(530);
                width: 100%;

                &:hover {
                    .thumbnail img {
                        transform: scale(1.05);
                    }

                    .readmore {
                        img {
                            transform: translateX(toVw(10));

                            @media (max-width: 768px) {
                                transform: translateX(toVwMobile(10));

                            }
                        }
                    }
                }

                @media (max-width: 768px) {
                    max-width: toVwMobile(327);
                    margin-right: toVwMobile(40);
                    display: inline-block;
                }

                .thumbnail {
                    display: block;
                    width: 100%;
                    height: toVw(273);
                    object-fit: cover;
                    object-position: left;
                    margin-bottom: toVw(30);
                    transition: .3s;
                    margin-top: toVw(10);

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        transition: .3s;
                        object-fit: cover;
                    }

                    @media (max-width: 768px) {
                        width: toVwMobile(278);
                        height: inherit;
                        margin-bottom: toVwMobile(30);
                    }
                }

                .date {
                    color: var(--text-grey, var(--text-grey));
                    font-family: 'Futura';
                    font-size: toVw(16);
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    margin-bottom: toVw(30);
                    opacity: .5;

                    @media (max-width: 768px) {
                        font-size: toVwMobile(16);
                        margin-bottom: toVwMobile(30);
                    }
                }

                .name {
                    color: var(--text-grey, var(--text-grey));
                    font-family: 'Futura';
                    font-size: toVw(64);
                    font-style: normal;
                    font-weight: 500;
                    line-height: 112%;
                    margin-bottom: toVw(30);

                    @media (max-width: 768px) {
                        white-space: normal;
                        font-size: toVwMobile(48);
                        margin-bottom: toVwMobile(40);
                    }
                }

                .readmore {
                    display: flex;
                    align-items: center;
                    color: #FDBB11;
                    font-family: 'Futura';
                    font-size: toVw(16);
                    font-style: normal;
                    font-weight: 500;
                    line-height: 79.5%;
                    letter-spacing: toVw(1.6);
                    text-transform: uppercase;
                    cursor: pointer;

                    @media (max-width: 768px) {
                        font-size: toVwMobile(16);
                    }

                    img {
                        display: block;
                        margin-right: toVw(20);
                        transition: .3s;

                        @media (max-width: 768px) {
                            margin-right: toVwMobile(20);
                        }
                    }
                }
            }
        }
    }
}

footer {
    padding-bottom: toVw(140);
    overflow: hidden;

    @media (max-width: 768px) {
        padding-bottom: toVwMobile(60);
    }

    .container {
        width: toVw(1400);

        @media (max-width: 768px) {
            position: relative;
            width: calc(100% - 9.3023255814vw);
        }

        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: toVw(85);

            @media (max-width: 768px) {
                flex-direction: column;
                margin-bottom: toVwMobile(20);
            }

            .left {
                color: var(--text-grayer);
                font-family: 'Futura';
                font-size: toVw(24);
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                max-width: toVw(491);
                position: relative;

                @media (max-width: 768px) {
                    font-size: toVwMobile(24);
                    max-width: 100%;

                    br {
                        display: none;
                    }
                }

                a {
                    color: #FDBB11;
                    font-family: 'Futura';
                    font-size: toVw(16);
                    font-style: normal;
                    font-weight: 500;
                    line-height: 79.5%;
                    letter-spacing: toVw(1.6);
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    bottom: toVw(8);

                    @media (max-width: 768px) {
                        font-size: toVwMobile(16);
                        bottom: inherit;
                        top: toVwMobile(70);
                    }

                    &:hover {
                        img {
                            transform: translateX(10px);
                        }
                    }

                    img {
                        display: block;
                        margin-right: toVw(20);
                        transition: .3s;

                        @media (max-width: 768px) {
                            margin-right: toVwMobile(20);
                        }
                    }
                }
            }

            .right {
                color: #FDBB11;
                text-align: right;
                font-family: 'Futura';
                font-size: toVw(83);
                font-style: normal;
                font-weight: 700;
                line-height: 111%;
                text-transform: uppercase;
                max-width: toVw(826);
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-wrap: wrap;

                p {
                    padding: 0 toVw(10);
                    position: relative;

                    &:first-child {
                        margin-right: -1px;
                    }

                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 2px;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 0;
                        background: #FDBB11;
                        transition: .3s;
                    }

                    @media (max-width: 768px) {
                        padding: 0 toVwMobile(10);
                    }
                }

                &:hover {
                    @media (min-width: 768px) {
                        p:before {
                            opacity: 0;
                        }
                    }
                }


                @media (max-width: 768px) {
                    font-size: toVwMobile(40);
                    max-width: 100%;
                    margin-top: toVwMobile(120);
                }
            }
        }

        .bot {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 768px) {
                flex-direction: column;
                margin-top: toVwMobile(30);
            }

            p {
                color: var(--text-grayer);
                font-family: 'Futura';
                font-size: toVw(24);
                font-style: normal;
                font-weight: 500;
                line-height: 120%;

                @media (max-width: 768px) {
                    font-size: toVwMobile(24);
                    position: absolute;
                    left: toVwMobile(0);
                    top: toVwMobile(110);
                }

                a {
                    color: inherit;
                }
            }

            .soc {
                color: var(--text-grey);
                text-align: right;
                font-family: 'Futura';
                font-size: toVw(16);
                font-style: normal;
                font-weight: 500;
                display: none;
                line-height: 79.5%;
                letter-spacing: toVw(0.88);
                text-transform: uppercase;
                transition: .3s;

                @media (max-width: 768px) {
                    font-size: toVwMobile(16);
                    color: var(--text-grey);
                    display: block;
                    position: absolute;
                    left: toVwMobile(0);
                    bottom: toVwMobile(2);
                }

                &:hover {
                    color: #FDBB11;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .rights {
                color: var(--text-grayer);
                font-family: 'Futura';
                font-size: toVw(15);
                font-style: normal;
                font-weight: 500;
                line-height: 120%;

                /* 18px */
                @media (max-width: 768px) {
                    font-size: toVwMobile(15);
                }
            }
        }
    }
}

.services {
    padding: toVw(100) 0;
    overflow: hidden;

    @media (max-width: 768px) {
        padding: toVwMobile(169) 0;
    }

    .container {
        a {
            color: #B4B4B4;
            text-align: center;
            font-family: 'Futura';
            font-size: toVw(101);
            font-style: normal;
            font-weight: 500;
            line-height: 109.5%;
            /* 110.595px */
            letter-spacing: toVw(-3.03);
            display: block;
            margin-bottom: toVw(13);
            opacity: .2;
            transition: .3s;
            cursor: url(../img/arrow-right-orange-icon.svg), pointer;

            @media (max-width: 768px) {
                color: var(--text-grey);
                font-size: toVwMobile(48);
                margin: 0 auto;
                margin-bottom: toVwMobile(40);
                opacity: 1;
                max-width: toVwMobile(340);
            }

            &:hover {
                color: var(--text-grey, var(--text-grey));
                opacity: 1;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.blog {
    .blog-main {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        margin-bottom: toVw(100);

        @media (max-width: 768px) {
            margin-bottom: toVwMobile(100);
        }

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            .blogimage {
                display: block;
                width: 100%;
                height: toVw(340);
                object-fit: contain;
                margin: 0 auto;
                margin-bottom: toVw(20);

                @media (max-width: 768px) {
                    width: 100%;
                    height: toVwMobile(200);
                    margin-bottom: toVwMobile(20);
                }
            }

            h1 {
                color: var(--text-grey, var(--text-grey));
                text-align: center;
                font-family: 'Futura';
                font-size: toVw(101);
                font-style: normal;
                font-weight: 500;
                line-height: 109.5%;
                letter-spacing: toVw(-3.03);
                margin: 0 auto;
                margin-bottom: toVw(10);
                max-width: toVw(864);

                @media (max-width: 768px) {
                    font-size: toVwMobile(58);
                    margin-bottom: toVwMobile(10);
                    max-width: 100%;
                }
            }

            .date {
                color: var(--text-grey, var(--text-grey));

                text-align: center;
                font-family: 'Futura';
                font-size: toVw(16);
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                opacity: 0.5;

                @media (max-width: 768px) {
                    font-size: toVwMobile(16);
                }
            }
        }
    }

    .blog-body {
        margin-bottom: toVw(250);

        @media (max-width: 768px) {
            margin-bottom: toVwMobile(130);
        }

        .container {
            max-width: toVw(1230);

            @media (max-width: 768px) {
                max-width: calc(100% - toVwMobile(40));
            }

            h2 {
                color: var(--text-grey, var(--text-grey));

                font-family: 'Futura';
                font-size: toVw(64);
                font-style: normal;
                font-weight: 500;
                line-height: 112%;
                margin-bottom: toVw(60);
                max-width: toVw(589);

                @media (max-width: 768px) {
                    font-size: toVwMobile(48);
                    margin-bottom: toVwMobile(30);
                    max-width: 100%;
                }
            }

            p {
                color: var(--text-grey, var(--text-grey));
                margin-bottom: toVw(15);
                font-family: 'Futura';
                font-size: toVw(20);
                font-style: normal;
                font-weight: 500;
                line-height: 137.5%;

                @media (max-width: 768px) {
                    font-size: toVwMobile(20);
                    margin-bottom: toVwMobile(15);
                }

                a {
                    color: #FDBB11;

                    &:hover {
                        &::before {
                            transform: rotate(45deg);
                        }
                    }

                    &::before {
                        content: '';
                        display: inline-block;
                        width: toVw(13);
                        height: toVw(13);
                        background-image: url(../img/link-arrow.svg);
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        transition: .3s;
                    }
                }
            }
        }
    }
}

.blog-list {
    padding-top: toVw(140);

    @media (max-width: 768px) {
        padding-top: toVwMobile(140);
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        max-width: toVw(1257);
        justify-content: space-between;

        @media (max-width: 768px) {
            max-width: toVwMobile(327);
        }

        .item {
            width: toVw(530);
            margin-bottom: toVw(250);
            flex-shrink: 0;

            @media (max-width: 768px) {
                width: toVwMobile(327);
                margin-right: toVwMobile(40);
                display: inline-block;
            }

            &:hover {
                .readmore {
                    img {
                        transform: translateX(toVw(10));

                        @media (max-width: 768px) {
                            transform: translateX(toVwMobile(10));

                        }
                    }
                }

                .thumbnail img {
                    transform: scale(1.05);
                }
            }

            .thumbnail {
                display: block;
                width: 100%;
                height: toVw(273);
                margin-bottom: toVw(30);
                object-fit: contain;
                object-position: left;
                margin-top: toVw(10);

                @media (max-width: 768px) {
                    width: 100%;
                    height: inherit;
                    margin-bottom: toVwMobile(30);
                    margin-top: 0;
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                    transition: .3s;
                }
            }

            .date {
                color: var(--text-grey, var(--text-grey));
                font-family: 'Futura';
                font-size: toVw(16);
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-bottom: toVw(30);
                opacity: .5;

                @media (max-width: 768px) {
                    font-size: toVwMobile(16);
                    margin-bottom: toVwMobile(30);
                }
            }

            .name {
                color: var(--text-grey, var(--text-grey));
                font-family: 'Futura';
                font-size: toVw(64);
                font-style: normal;
                font-weight: 500;
                line-height: 112%;
                margin-bottom: toVw(30);

                @media (max-width: 768px) {
                    white-space: normal;
                    font-size: toVwMobile(48);
                    margin-bottom: toVwMobile(40);
                }
            }

            .readmore {
                display: flex;
                align-items: center;
                color: #FDBB11;
                font-family: 'Futura';
                font-size: toVw(16);
                font-style: normal;
                font-weight: 500;
                line-height: 79.5%;
                letter-spacing: toVw(1.6);
                text-transform: uppercase;
                cursor: pointer;

                @media (max-width: 768px) {
                    font-size: toVwMobile(16);
                }

                img {
                    display: block;
                    margin-right: toVw(20);
                    transition: .3s;

                    @media (max-width: 768px) {
                        margin-right: toVwMobile(20);
                    }
                }
            }
        }
    }
}

.cases-page-container {
    @media (max-width: 768px) {
        margin-top: toVwMobile(140);
    }
}

.form-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(32, 31, 28, 0.57);
    backdrop-filter: blur(14.5px);
    z-index: 11;
    display: none;

    @media (max-width: 768px) {}

    &.active {
        opacity: 1;
        z-index: 11;
        pointer-events: inherit;
    }

    form {
        display: block;
        height: 100%;
        width: 100%;
    }

    .close {
        position: absolute;
        top: toVw(70);
        right: toVw(309);
        cursor: pointer;
        color: #FDBB11;
        font-family: 'Futura';
        font-size: toVw(16);
        font-style: normal;
        font-weight: 500;
        line-height: 79.5%;
        letter-spacing: toVw(1.6);
        text-transform: uppercase;
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            top: toVwMobile(20);
            right: toVwMobile(32);
            font-size: toVwMobile(16);
        }

        &:hover {
            &::after {
                transform: scale(1.1);
            }
        }

        &::after {
            content: '';
            display: block;
            width: toVw(26);
            height: toVw(26);
            background-image: url(../img/close-icon-orange.svg);
            background-size: cover;
            background-repeat: no-repeat;
            margin-left: toVw(20);
            transition: .3s;

            @media (max-width: 768px) {
                width: toVwMobile(26);
                height: toVwMobile(26);
                margin-left: toVwMobile(20);
            }
        }
    }

    .wrapper {
        height: 100%;
        width: toVw(830);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: toVw(60) 0 toVw(60) 0;

        @media (max-width: 768px) {
            width: calc(100% - toVwMobile(64));
            padding: toVwMobile(60) 0 toVwMobile(60) 0;
        }

        .iw {
            width: 100%;
            position: relative;

            &.empty {
                .empty {
                    opacity: 1;
                    z-index: 1;
                }

                input,
                textarea {
                    border-bottom: toVw(2) solid red;

                    &::placeholder {
                        color: red;
                    }
                }
            }

            &.incorrectEmail {
                .incorrect {
                    opacity: 1;
                    z-index: 1;
                }

                input,
                textarea {
                    border-bottom: toVw(2) solid red;

                    &::placeholder {
                        color: red;
                    }
                }
            }

            .err {
                color: var(--text-grey, var(--text-grey));
                font-family: 'Futura';
                font-size: toVw(23);
                display: none;
                font-style: normal;
                font-weight: 500;
                line-height: 112%;
                position: absolute;
                right: 0;
                top: 0;
                padding: toVw(5);
                border-radius: toVw(5);
                background: rgba($color: #000000, $alpha: .5);
                z-index: -100;
                pointer-events: none;
                opacity: 0;

                @media (max-width: 768px) {
                    font-size: toVwMobile(20);
                }
            }
        }

        input,
        textarea {
            border-radius: 0;
            border: none;
            outline: none;
            background: none;
            resize: none;
            height: toVw(112);
            color: var(--text-grey, var(--text-grey));
            font-family: 'Futura';
            font-size: toVw(64);
            font-style: normal;
            font-weight: 500;
            line-height: 112%;
            padding-bottom: toVw(20);
            border-bottom: toVw(2) solid #fff;
            width: 100%;

            @media (max-height: 700px) {
                height: toVw(90);
            }

            @media (max-width: 768px) {
                height: toVwMobile(90);
                font-size: toVwMobile(64);
                padding-bottom: toVwMobile(20);
                border-bottom: toVwMobile(2) solid #fff;
            }

            &::placeholder {
                color: var(--text-grey, var(--text-grey));
                font-family: 'Futura';
                font-size: toVw(64);
                font-style: normal;
                font-weight: 500;
                line-height: 112%;

                @media (max-width: 768px) {
                    font-size: toVwMobile(64);
                }
            }
        }

        textarea {
            * {
                scrollbar-width: auto;
                scrollbar-color: #FDBB11 #ffffff;
            }

            /* Chrome, Edge, and Safari */
            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background: #ffffff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #FDBB11;
                border-radius: 4px;
                border: 0px solid #ffffff;
            }

            &.shrink {
                color: var(--text-grey, var(--text-grey));
                font-family: 'Futura';
                font-size: toVw(23);
                font-style: normal;
                font-weight: 500;
                line-height: 112%;

                @media (max-width: 768px) {
                    font-size: toVwMobile(20);
                }
            }
        }

        .drag-n-drop-area {
            position: relative;
            height: toVw(176);

            @media (max-width: 768px) {
                height: toVwMobile(136);
            }

            .filelist {
                height: 100%;

                p {
                    color: #E9E6E6;
                    font-family: 'Futura';
                    font-size: toVw(16);
                    font-style: normal;
                    font-weight: 500;
                    line-height: 79.5%;
                    letter-spacing: 0.88px;
                    text-transform: uppercase;
                    margin-bottom: toVw(10);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @media (max-width: 768px) {
                        font-size: toVwMobile(16);
                    }
                }
            }

            .w {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: repeating-linear-gradient(-43deg, #ffffff, #ffffff 13px, transparent 13px, transparent 26px, #ffffff 26px), repeating-linear-gradient(47deg, #ffffff, #ffffff 13px, transparent 13px, transparent 26px, #ffffff 26px), repeating-linear-gradient(137deg, #ffffff, #ffffff 13px, transparent 13px, transparent 26px, #ffffff 26px), repeating-linear-gradient(227deg, #ffffff, #ffffff 13px, transparent 13px, transparent 26px, #ffffff 26px);
                background-size: toVw(2) 100%, 100% toVw(2), toVw(2) 100%, 100% toVw(2);
                background-position: 0 0, 0 0, 100% 0, 0 100%;
                background-repeat: no-repeat;
                cursor: pointer;
                transition: .3s;

                &.active {
                    background-size: toVw(4) 100%, 100% toVw(4), toVw(4) 100%, 100% toVw(4);
                }

                p {
                    color: var(--text-grey, var(--text-grey));
                    text-align: center;
                    font-family: 'Futura';
                    font-size: toVw(64);
                    font-style: normal;
                    font-weight: 500;
                    line-height: 112%;

                    &.formob {
                        display: none;
                    }

                    @media (max-width: 768px) {
                        font-size: toVwMobile(58);

                        &.formob {
                            display: block;
                        }

                        &.fordesktop {
                            display: none;
                        }
                    }
                }

                @media (max-width: 768px) {
                    background: none;
                    border: toVw(2) dashed #fff;
                }
            }

            input {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                z-index: -100;
            }
        }

        .btn-send {
            color: #FDBB11;
            font-family: 'Futura';
            font-size: toVw(16);
            font-style: normal;
            font-weight: 500;
            line-height: 79.5%;
            letter-spacing: toVw(1.6);
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            @media (max-width: 768px) {
                font-size: toVwMobile(16);
            }

            &:hover {
                &::before {
                    transform: translateX(toVw(10));

                    @media (max-width: 768px) {
                        transform: translateX(toVwMobile(10));
                    }
                }
            }

            &::before {
                content: '';
                display: inline-block;
                width: toVw(33);
                height: toVw(8);
                background-image: url(../img/arrow-right-orange-icon.svg);
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: toVw(20);
                transition: .3s;

                @media (max-width: 768px) {
                    width: toVwMobile(33);
                    height: toVwMobile(8);
                    margin-right: toVwMobile(20);
                }
            }
        }
    }
}

.service {
    .service-main {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        margin-bottom: toVw(100);

        @media (max-width: 768px) {
            margin-bottom: toVwMobile(100);
        }

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            h1 {
                font-family: 'Futura';
                font-size: toVw(101);
                font-weight: 500;
                line-height: toVw(111);
                text-align: center;
                color: var(--text-grey);

                @media (max-width: 768px) {
                    font-size: toVwMobile(58);
                    line-height: toVwMobile(63);
                }
            }
        }
    }

    .service-body {
        margin-bottom: toVw(250);

        @media (max-width: 768px) {
            margin-bottom: toVwMobile(130);
        }

        .container {
            max-width: toVw(1230);

            @media (max-width: 768px) {
                max-width: calc(100% - toVwMobile(40));
            }

            h2,
            h1 {
                color: var(--text-grey, var(--text-grey));
                font-family: 'Futura';
                font-size: toVw(64);
                font-style: normal;
                font-weight: 500;
                line-height: 112%;
                margin-top: toVw(100);
                margin-bottom: toVw(60);
                max-width: toVw(982);

                @media (max-width: 768px) {
                    font-size: toVwMobile(48);
                    margin-bottom: toVwMobile(30);
                    margin-top: toVwMobile(30);
                    max-width: 100%;
                }
            }

            ol,
            ul {
                li {
                    margin-bottom: toVw(28);

                    @media (max-width: 768px) {
                        margin-bottom: toVwMobile(28);
                    }
                }
            }

            ol {
                counter-reset: item;
                margin-bottom: toVw(100);

                @media (max-width: 768px) {
                    margin-bottom: toVwMobile(30);
                }

                li {
                    padding-left: toVw(30);
                    position: relative;

                    &:before {
                        display: inline-block;
                        content: counter(item) ". ";
                        counter-increment: item;
                        position: absolute;
                        left: 0;
                        top: 0;

                    }

                    @media (max-width: 768px) {
                        padding-left: toVwMobile(30);
                    }
                }
            }

            ul {
                list-style: disc;
                padding-left: toVw(40);
                margin-bottom: toVw(10);

                @media (max-width: 768px) {
                    padding-left: toVwMobile(20);
                    margin-bottom: toVwMobile(10);
                }

                li {
                    padding-left: 0;

                    &:before {
                        display: none;
                    }
                }
            }

            p,
            ul,
            ol {
                max-width: toVw(824);

                @media (max-width: 768px) {
                    max-width: 100%;
                }
            }

            p,
            li {
                color: var(--text-grey, var(--text-grey));
                font-family: 'Futura';
                font-size: toVw(20);
                font-style: normal;
                font-weight: 500;
                line-height: 137.5%;

                b,
                strong {
                    font-family: 'Bold';
                }

                @media (max-width: 768px) {
                    font-size: toVwMobile(20);
                }


                a {
                    color: #FDBB11;

                    &:hover {
                        &::before {
                            transform: rotate(45deg);
                        }
                    }

                    &::before {
                        content: '';
                        display: inline-block;
                        width: toVw(13);
                        height: toVw(13);
                        background-image: url(../img/link-arrow.svg);
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        transition: .3s;
                    }
                }
            }
        }
    }
}

.case {
    .case-main {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        margin-bottom: toVw(0);

        @media (max-width: 768px) {
            margin-bottom: toVwMobile(0);
        }

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            h1 {
                font-family: 'Futura';
                font-size: toVw(101);
                font-weight: 500;
                line-height: toVw(111);
                text-align: center;
                color: var(--text-grey);
                margin-bottom: toVw(40);

                @media (max-width: 768px) {
                    font-size: toVwMobile(58);
                    line-height: toVwMobile(63);
                }
            }

            .caseimage {
                width: toVw(562);
                height: toVw(360);
                object-fit: cover;
                display: block;
                margin: 0 auto;
                margin-bottom: toVw(40);

                @media (max-width: 768px) {
                    width: 100%;
                    height: toVwMobile(250);
                }
            }

            .ext-link {
                color: #FDBB11;
                font-family: 'Futura';
                font-size: toVw(16);
                font-style: normal;
                font-weight: 500;
                line-height: 79.5%;
                text-transform: uppercase;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 768px) {
                    font-size: toVwMobile(16);
                }

                &:hover {
                    &::before {
                        transform: translateX(toVw(10));
                    }
                }

                &::before {
                    content: '';
                    display: inline-block;
                    width: toVw(33);
                    height: toVw(8);
                    background-image: url(../img/arrow-right-orange-icon.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    margin-right: toVw(20);
                    transition: .3s;

                    @media (max-width: 768px) {
                        width: toVwMobile(33);
                        height: toVwMobile(8);
                    }
                }
            }
        }
    }

    .slick-slider {
        margin: toVw(80) 0;
        padding-left: toVw(249);

        @media (max-width: 768px) {
            margin: toVwMobile(80) 0;
            padding-left: toVwMobile(20);
        }

        .item {
            margin-right: toVw(40);
            border: none !important;
            outline: none !important;
            transition: .3s;

            &.ho {
                opacity: .2;
            }

            @media (max-width: 768px) {
                margin-right: toVwMobile(10);
            }

            img {
                width: toVw(1052);
                height: toVw(538);
                object-fit: contain;

                @media (max-width: 768px) {
                    width: toVwMobile(319);
                    height: toVwMobile(634);
                }
            }
        }
    }

    .mobileSliderDesktop .slick-slider {
        .item {
            margin-right: toVw(20);

            @media (max-width: 768px) {
                margin-right: toVwMobile(10);
            }

            img {
                width: auto;
                height: toVw(638);

                @media (max-width: 768px) {
                    width: toVwMobile(319);
                    height: toVwMobile(634);
                }
            }
        }
    }

    .desktopSlider {
        &.dpn {
            display: none;
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    .mobileSlider {
        display: none;

        &.mobileSliderDesktop {
            display: block;
        }

        @media (max-width: 768px) {
            display: block;
        }
    }

    .arrows {
        margin: toVw(20) 0 toVw(80);
        padding-left: toVw(calc(249 + 1052 - 96));
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: fit-content;

        @media (max-width: 768px) {
            display: none;
        }

        .arrow {
            width: toVw(33);
            cursor: pointer;
            transition: .3s;

            &.prevArrow {
                margin-right: toVw(30);

                &:hover {
                    img {
                        @media (min-width: 768px) {
                            transform: translateX(toVw(-10));
                        }
                    }
                }
            }

            &.nextArrow {
                &:hover {
                    img {
                        @media (min-width: 768px) {
                            transform: translateX(toVw(10));
                        }
                    }
                }
            }

            img {
                display: block;
                width: 100%;
                object-fit: contain;
                transition: .3s;
            }
        }
    }

    .case-body {
        margin-bottom: toVw(150);

        @media (max-width: 768px) {
            margin-bottom: toVwMobile(130);
        }

        .container {
            max-width: toVw(1230);

            @media (max-width: 768px) {
                max-width: calc(100% - toVwMobile(40));
            }

            h2 {
                color: var(--text-grey, var(--text-grey));

                font-family: 'Futura';
                font-size: toVw(64);
                font-style: normal;
                font-weight: 500;
                line-height: 112%;
                margin-bottom: toVw(60);
                max-width: toVw(589);

                @media (max-width: 768px) {
                    font-size: toVwMobile(48);
                    margin-bottom: toVwMobile(30);
                    max-width: 100%;
                }
            }

            p {
                color: var(--text-grey, var(--text-grey));

                font-family: 'Futura';
                font-size: toVw(20);
                font-style: normal;
                font-weight: 500;
                line-height: 137.5%;

                @media (max-width: 768px) {
                    font-size: toVwMobile(20);
                }

                a {
                    color: #FDBB11;

                    &:hover {
                        &::before {
                            transform: rotate(45deg);
                        }
                    }

                    &::before {
                        content: '';
                        display: inline-block;
                        width: toVw(13);
                        height: toVw(13);
                        background-image: url(../img/link-arrow.svg);
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        transition: .3s;
                    }
                }
            }
        }
    }
}

.succeded {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: toVw(300);
    padding: toVwMobile(30);
    border-radius: toVwMobile(10);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #E9E6E6, $alpha: .5);
    color: var(--text-dark);
    font-family: 'Futura';
    font-size: toVwMobile(16);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    display: none;

    p {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &.active {
        opacity: 1;
        z-index: 5;
        pointer-events: inherit;
    }
}

.page-404 {
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
        font-family: bold;
        font-size: 200px;
        font-weight: 800;
        color: #FDBB11;

        @media (max-width: 768px) {
            font-size: toVwMobile(210);
        }
    }

    p {
        font-family: 'Futura';
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 20px;
        color: #fff;
    }

    a {
        font-family: 'Futura';
        font-weight: 500;
        font-size: 18px;
        color: #FDBB11;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;

        &:hover {
            img {
                transform: translateX(10px);
            }
        }

        img {
            display: block;
            width: 33px;
            margin-right: 20px;
            transition: .3s;
        }
    }
}


.team {



    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8.6805555556vw 0;
    min-height: 56.1342592593vw;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        width: calc(100% - 9.3023255814vw);
    }

    .imageContainer {
        width: #{toVw(562)};
        height: #{toVw(359)};

        @media screen and (max-width: 768px) {
            width: 100%;
            height: 46.511627907vw;
            margin-bottom: 4.6511627907vw;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    h1 {
        font-family: 'Futura';
        font-size: toVw(101);
        font-weight: 500;
        line-height: toVw(111);
        text-align: center;
        color: var(--text-grey);
        margin-top: toVw(20);

        @media (max-width: 768px) {
            font-size: toVwMobile(58);
            line-height: toVwMobile(63);
        }
    }



    .philosophy {

        max-width: 71.1805555556vw;
        width: 94.212962963vw;
        margin: 0 auto;
        margin-top: toVw(293);

        @media screen and (max-width: 768px) {
            max-width: calc(100% - 9.3023255814vw);
            width: calc(100% - 9.3023255814vw);
            margin-top: 23.2558139535vw;
        }

        h2 {
            color: var(--text-grey);
            font-family: 'Futura';
            font-size: 3.7037037037vw;
            font-style: normal;
            font-weight: 700;
            line-height: 112%;

            @media screen and (max-width: 768px) {
                font-size: 8.1627906977vw;
            }
        }

        p {
            color: var(--text-grey);

            font-family: 'Futura';
            font-size: 1.1574074074vw;
            font-style: normal;
            font-weight: 700;
            line-height: 137.5%;

            @media screen and (max-width: 768px) {
                font-size: 4.6511627907vw;
            }
        }
    }

    .team-container {
        max-width: 71.1805555556vw;
        width: 94.212962963vw;
        margin: 0 auto;
        margin-top: toVw(106);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: toVw(100) toVw(40);

        // @media screen and (max-width: 1024px) {
        //     display: grid;
        //     grid-template-columns: 1fr 1fr;
        // }
        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 768px) {
            max-width: calc(100% - 9.3023255814vw);
            width: calc(100% - 9.3023255814vw);
            margin-top: 23.2558139535vw;
        }

        .person {
            width: 100%;
            // max-width: toVw(338);
            display: flex;
            flex-direction: column;

            &:hover {
                h3 {
                    color: #F6D13B;
                }
            }

            img {
                width: 100%;
                object-fit: cover;
            }

            p {
                margin-top: toVw(30);
                color: var(--text-grey);
                opacity: 0.5;
                font-family: Futura;
                font-size: toVw(16);
                font-style: normal;
                font-weight: 500;
                line-height: 120%;

                @media screen and (max-width: 768px) {
                    font-size: 2.6511627907vw;
                }
            }

            h3 {
                color: var(--text-grey);

                font-family: 'Futura';
                font-size: toVw(48);
                font-style: normal;
                font-weight: 700;
                line-height: 112%;
                transition: 0.3s ease all;


                @media screen and (max-width: 768px) {
                    font-size: toVw(68);
                }

            }
        }
    }


}

.team-single-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8.6805555556vw 0;
    min-height: 56.1342592593vw;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        width: calc(100% - 9.3023255814vw);
    }

    .person-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            max-width: toVw(278);
            height: toVw(393);
            object-fit: cover;

            @media screen and (max-width: 768px) {
                // width: calc(100% - 20px);
                max-width: none;
                aspect-ratio: 9 / 16;
                height: 60vh;
            }
        }

        h2 {
            margin-top: toVw(20);
            color: var(--text-grey);
            font-family: 'Futura';
            font-size: 3.7037037037vw;
            font-style: normal;
            font-weight: 700;
            line-height: 112%;

            @media screen and (max-width: 768px) {
                font-size: 8.1627906977vw;
                margin-top: toVw(40);
            }
        }

        p {
            color: var(--text-grey);
            max-width: toVw(963);
            font-family: 'Futura';
            font-size: 1.1574074074vw;
            font-style: normal;
            font-weight: 700;
            line-height: 137.5%;
            text-align: center;
            margin-top: toVw(40);

            @media screen and (max-width: 768px) {
                font-size: 4.6511627907vw;
                max-width: 90vw;
                margin-top: toVw(120);
            }

        }

        .soccial {
            margin-top: toVw(30);
            display: flex;

            a {
                padding: toVw(10);
                color: #838282;

                font-family: 'Futura';
                font-size: toVw(16);
                font-style: normal;
                font-weight: 500;
                line-height: 79.5%;
                /* 12.72px */
                letter-spacing: 0.88px;

                @media screen and (max-width: 768px) {
                    font-size: 3.6511627907vw;
                    padding: toVw(80) toVw(40);
                }
            }
        }
    }

    .publication{
        margin-top: toVw(154);
    }

}